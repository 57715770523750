<template>
    <form class="create-form">
        <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
            <Preloader />
        </div>

        <div class="flex flex-col gap-4 mt-4">
            <div class="relative col-span-6">
                <label for="form-question" class="form-label">
                    Название вопроса
                    <sup v-if="v$.form.question.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.question.$errors.length" for="form-question" class="form-input-text-error">
                    {{ getOverrideMessage(v$.form.question.$errors[0].$message) }}
                </label>
                <input
                    id="form-question"
                    v-model="form.question"
                    type="text"
                    class="form-control w-full"
                    :class="{ 'form-input-error': v$.form.question.$errors.length }"
                    placeholder=""
                    autocomplete="off"
                    :data-value="form.question"
                />
            </div>
            <div :class="{ 'form-editor-error': v$.form.answer.$errors.length }">
                <label for="form-answer" class="form-label">
                    Описание вопроса
                    <sup v-if="v$.form.answer.required" class="text-theme-6">*</sup>
                </label>
                <CustomWyswig id="form-answer" v-model="form.answer" />
            </div>
        </div>

        <div class="col-span-12 flex items-center justify-end mt-5">
            <router-link to="#" class="btn btn-secondary w-24 mr-2 mb-2" @click.prevent="redirectToMainPage"
                >Отмена</router-link
            >
            <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="saveAndQuit">
                Сохранить и выйти
            </button>
            <button type="submit" class="btn btn-primary mb-2" @click.prevent="saveAndEdit">Сохранить</button>
        </div>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import CustomWyswig from '@/components/base/custom-wyswig/Main';
import { errorResponse } from '@/mixins/form/form-mixin';
import { pageMixin } from '@/mixins/pageMixin';
import Preloader from '@/components/preloader/Main';
import { formPreparation } from '@/utils/object-to-form-data';

export default {
    name: 'Edit',
    components: { CustomWyswig },
    mixins: [errorResponse, pageMixin, Preloader],
    setup() {
        return {
            v$: useVuelidate(),
        };
    },

    data() {
        return {
            loading: false,
            form: {
                question: '',
                answer: '',
            },
        };
    },

    async created() {
        await this.fetchData();
    },

    validations() {
        return {
            form: {
                question: { required },
                answer: { required },
            },
        };
    },

    mounted() {
        this.$store.commit('main/setPageHeader', 'Редактирование вопроса');
    },

    methods: {
        async save() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;
            this.form.seo = this.seo;

            try {
                const formData = formPreparation(this.form, { nullsAsUndefineds: false, imagesAsObject: true });
                const { data } = await this.axios.post(`/faq/${this.$route.params.id}`, formData, {
                    params: {
                        _method: 'patch',
                    },
                });
                this.$notify(data.message);
                this.loading = false;
                return true;
            } catch (ex) {
                this.errorResponse(ex);
                this.loading = false;
                return false;
            }
        },
        async fetchData() {
            this.loading = true;
            this.axios
                .get(`/faq/${this.$route.params.id}/edit`)
                .then(({ data }) => {
                    this.form.question = data?.data?.question;
                    this.form.answer = data?.data?.answer;
                    this.loading = false;
                })
                .catch((ex) => {
                    console.log('cant fetch single data: ' + ex);
                    this.loading = false;
                    // this.$router.push('/error-page');
                });
        },
        async saveAndQuit() {
            const success = await this.save();
            if (success) this.redirectToMainPage();
        },
        async saveAndEdit() {
            const success = await this.save();
            if (success) this.$router.push({ name: 'faq', query: { id: data.data.id } });
        },

        redirectToMainPage() {
            this._pMTransitionToEditPageWithQuery('faq');
        },
    },
};
</script>
